<template>
  <button
    :class="{ playing: isAudioPlaying, paused: !isAudioPlaying }"
    @click="toggle"
  >
    <BaseSpriteIcon :icon-name="icon" />
  </button>
</template>
<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
const PLAY_ICON = 'ico-book-info-read';
const PAUSE_ICON = 'ico-stop';

export default {
  name: 'ScrubberPlayButton',
  components: {
    BaseSpriteIcon
  },
  computed: {
    currentParaId() {
      return this.$store.state.PlaybackStore.currentParaId;
    },
    isAudioPlaying() {
      return this.$store.state.PlaybackStore.isPlaying;
    },
    icon() {
      return this.isAudioPlaying ? PAUSE_ICON : PLAY_ICON;
    }
  },
  methods: {
    toggle() {
      if (this.isAudioPlaying) {
        this.$store.dispatch('PlaybackStore/pause');
      } else {
        this.$store.dispatch('PlaybackStore/continue');
      }
    }
  }
};
</script>
