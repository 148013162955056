<template>
  <div class="about-content" :class="{ '-device': isDevice }">
    <section class="header-block">
      <div class="about-wrapper">
        <div class="header-content">
          <div class="header-content-block">
            <div class="header-text-block">
              <h2>{{ $t('ProductName') }}</h2>
              <div class="header-title-description">
                Free Immersive Interfaith Library
              </div>
            </div>
          </div>
          <div class="header-pic-block">
            <v-img
              class="header-pic1"
              :lazy-src="headerPic1"
              :src="headerPic1"
              alt="header-pic"
            />
          </div>
          <div class="header-content-block">
            <div class="header-text-block">
              <div class="header-text-description">
                Ocean is a custom ebook reader designed for exploration and
                study of the sacred literature of the world's religious
                traditions. It provides a simple cross-platform reading
                experience with an immersive audio-integrated reading experience
                and useful study and research tools.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="platforms-block">
      <div class="about-wrapper">
        <div class="platforms-list">
          <div class="platforms-list-container">
            <div class="platforms-item">All platforms!</div>
            <div class="platforms-item">
              <BaseSpriteIcon :icon-name="'ico-globe'" />
              Web
            </div>
            <div class="platforms-item">
              <BaseSpriteIcon :icon-name="'ico-portal-win'" />
              Windows
            </div>
            <div class="platforms-item">
              <BaseSpriteIcon :icon-name="'ico-soc-apple'" />
              macOS
            </div>
            <div class="platforms-item">
              <BaseSpriteIcon :icon-name="'ico-portal-lin'" />
              Linux
            </div>
            <div class="platforms-item">
              <BaseSpriteIcon :icon-name="'ico-portal-and'" />
              Android
            </div>
            <div class="platforms-item">
              <BaseSpriteIcon :icon-name="'ico-soc-apple'" />
              iOS
            </div>
            <div class="platforms-item item-dummy">All platforms!</div>
            <div class="platforms-item item-dummy">
              <BaseSpriteIcon :icon-name="'ico-globe'" />
              Web
            </div>
            <div class="platforms-item item-dummy">
              <BaseSpriteIcon :icon-name="'ico-portal-win'" />
              Windows
            </div>
            <div class="platforms-item item-dummy">
              <BaseSpriteIcon :icon-name="'ico-soc-apple'" />
              macOS
            </div>
            <div class="platforms-item item-dummy">
              <BaseSpriteIcon :icon-name="'ico-portal-lin'" />
              Linux
            </div>
            <div class="platforms-item item-dummy">
              <BaseSpriteIcon :icon-name="'ico-portal-and'" />
              Android
            </div>
            <div class="platforms-item item-dummy">
              <BaseSpriteIcon :icon-name="'ico-soc-apple'" />
              iOS
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-block section-one -text -teal">
      <div class="about-wrapper">
        <div class="text-block1">immersive reading</div>
        <h2 class="title-block">Combining the Ear and the Eye</h2>
        <p class="text-block2">
          By combining ear and eye, you get an immersive reading experience in
          which comprehension and vocabulary acquisition is optimized.
        </p>
        <BaseButton
          v-if="!isOffline"
          color="primary"
          x-large
          elevation="0"
          class="mb-2"
          @click="playBookFragment"
          ><BaseSpriteIcon icon-name="ico-book-info-read-solid" />Play book
          fragment</BaseButton
        >
        <p v-if="!isOffline" class="section-bottom-text">
          Make sure that the sound is on
        </p>
      </div>
    </section>
    <section class="section-block section-one -pic">
      <div class="about-wrapper">
        <BookPreview></BookPreview>
      </div>
    </section>
    <section class="section-block section-three">
      <div class="about-wrapper">
        <h2 v-if="false" class="title-block">Benefits of Immersive Reading</h2>
        <div class="features-wrapper">
          <div class="features-block">
            <div class="features-item">
              <div class="pic-block">
                <div class="item-title">Read</div>
                <v-img
                  :lazy-src="featuresPic5"
                  :src="featuresPic5"
                  alt="features-picture"
                />
              </div>
              <div class="text-block">
                <div class="item-title">Read</div>
                <p>
                  The Ocean library contains a vast collection of the world's
                  great religious classics
                </p>
              </div>
            </div>
            <div class="features-item">
              <div class="pic-block">
                <div class="item-title">Listen</div>
                <v-img
                  :lazy-src="featuresPic6"
                  :src="featuresPic6"
                  alt="features-picture"
                />
              </div>
              <div class="text-block">
                <div class="item-title">Listen</div>
                <p>
                  Many books are word-aligned with an audio reading to provide
                  an enjoyable experience
                </p>
              </div>
            </div>
            <div class="features-item">
              <div class="pic-block">
                <div class="item-title">Search</div>
                <v-img
                  :lazy-src="featuresPic7"
                  :src="featuresPic7"
                  alt="features-picture"
                />
              </div>
              <div class="text-block">
                <div class="item-title">Search</div>
                <p>
                  Full text search across thousands of books, articles and
                  website resources
                </p>
              </div>
            </div>
            <div class="features-item">
              <div class="pic-block">
                <div class="item-title">Note</div>
                <v-img
                  :lazy-src="featuresPic8"
                  :src="featuresPic8"
                  alt="features-picture"
                />
              </div>
              <div class="text-block">
                <div class="item-title">Note</div>
                <p>
                  Add your own notes, filter and organize notes for
                  presentations or study
                </p>
              </div>
            </div>
          </div>
          <!--/features-block-->
          <DonationBlock
            v-if="!isDevice"
            class="card card-row donation-block"
            :donation-title="donationBlockTitle"
            :donation-description="donationBlockDescription"
            :control-label="donationControlLabel"
          />
        </div>
      </div>
    </section>
    <section v-if="false" class="section-block section-four">
      <div class="about-wrapper">
        <div class="pics-block">
          <div>
            <v-img :lazy-src="leftImgOcean" :src="leftImgOcean" alt="picture" />
          </div>
          <div>
            <v-img
              :lazy-src="rightImgOcean"
              :src="rightImgOcean"
              alt="picture"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="section-block section-one -text">
      <div class="about-wrapper">
        <div class="text-block1">Interfaith Library</div>
        <h2 class="title-block2">
          {{ $t('ProductName') }} contains hundreds of interfaith books,
          narrated by gifted artists and aligned word for word.
        </h2>
      </div>
    </section>
    <section class="section-block section-five">
      <div class="about-wrapper">
        <div class="religions-block">
          <ReligionItem
            v-for="(item, index) in religions"
            :key="index"
            :religion-link="toCategoryLink(item.religionName)"
            :is-wide="item.isWide"
            :religion-name="item.religionName"
            :religion-ico="item.religionIco"
          />
        </div>
        <div class="button-block mb-14">
          <BaseButton
            color="#fc6539"
            dark
            x-large
            elevation="0"
            @click="openLibrary"
            >Open library</BaseButton
          >
        </div>
      </div>
    </section>
    <AppFooter />
  </div>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import DonationBlock from '@/components/base/DonationBlock/DonationBlock.vue';
import AppFooter from '@/components/views/AppFooter/AppFooter.vue';
import BookPreview from '@/components/views/BookPreview/BookPreview.vue';

import headerPic1 from '@/components/views/AboutView/ocean/img/header_img_ocean.webp';
import featuresPic5 from '@/components/views/AboutView/ocean/img/features-pic5.svg';
import featuresPic6 from '@/components/views/AboutView/ocean/img/features-pic6.svg';
import featuresPic7 from '@/components/views/AboutView/ocean/img/features-pic7.svg';
import featuresPic8 from '@/components/views/AboutView/ocean/img/features-pic8.svg';
import leftImgOcean from '@/components/views/AboutView/ocean/img/left_img_ocean.webp';
import rightImgOcean from '@/components/views/AboutView/ocean/img/right_img_ocean.webp';

import ReligionItem from './ReligionItem.vue';

import PublicationLogicService from '@/services/PublicationLogic/PublicationLogicService';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';
import ManagePublicationsStatesEnum from '@/enums/ManagePublicationsStatesEnum';
import AppStateEnum from '@/enums/AppStateEnum';
import { mapGetters } from 'vuex';

export default {
  name: 'AboutViewFfa',
  components: {
    BaseSpriteIcon,
    BaseButton,
    DonationBlock,
    AppFooter,
    BookPreview,
    ReligionItem
  },
  data() {
    const religions = [
      {
        religionIco: 'ico-hindu',
        religionName: 'Hindu',
        religionLink: ''
      },
      {
        religionIco: 'ico-zoroastrian',
        religionName: 'Zoroastrian',
        religionLink: ''
      },
      {
        religionIco: 'ico-judaism',
        religionName: 'Judaism',
        religionLink: ''
      },
      {
        religionIco: 'ico-jainism',
        religionName: 'Jainism',
        religionLink: ''
      },
      {
        religionIco: 'ico-confucian',
        religionName: 'Confucian',
        religionLink: '',
        isWide: true
      },
      {
        religionIco: 'ico-buddhist',
        religionName: 'Buddhist',
        religionLink: '',
        isWide: true
      },
      {
        religionIco: 'ico-tao',
        religionName: 'Tao',
        religionLink: ''
      },
      {
        religionIco: 'ico-christian',
        religionName: 'Christian',
        religionLink: ''
      },
      {
        religionIco: 'ico-islam',
        religionName: 'Islam',
        religionLink: ''
      },
      {
        religionIco: 'ico-bahai',
        religionName: 'Bahá’í',
        religionLink: ''
      }
    ];
    const donationBlockTitle = 'Support our project';
    const donationBlockDescription =
      'We take pride in being a non-profit organization that offers free access to learning about various religious traditions. You can support the development of our project either through a one-time donation or by opting for monthly contributions.<br /><br />IMMERSIVE OCEAN, INC';

    return {
      headerPic1,
      featuresPic5,
      featuresPic6,
      featuresPic7,
      featuresPic8,
      leftImgOcean,
      rightImgOcean,
      religions,
      YTIdFQY: 'CsiU61jmD-8',
      currentLanguage: this.$store.getters[
        'ContextStore/currentLanguageGetter'
      ],
      donationBlockTitle,
      donationBlockDescription,
      donationControlLabel: this.$t('App.Button.label.Donate')
    };
  },
  computed: {
    ...mapGetters('ContextStore', {
      OS: 'getPlatform'
    }),
    isOffline() {
      return !this.$store.getters['ContextStore/isOnline'];
    },
    isDevice() {
      return this.$store.getters['ContextStore/isDevice'];
    },
    bookCategories() {
      const builder = SearchPublicationsFactory.getSearcherBuilder();
      const searcher = builder
        .setState(ManagePublicationsStatesEnum.BOOK_LIST)
        .setLanguage(this.currentLanguage)
        .setCollectionIncluded(true)
        .build();
      const bookCategories = this.$store.getters['LibraryStore/getCategories'](
        searcher
      );
      return bookCategories;
    }
  },
  methods: {
    openLibrary() {
      this.$router.push({ name: 'library' });
    },
    openHelpCenter() {
      this.$router.push({ name: 'helpCenter' });
    },
    isAudioPlaying() {
      return this.$store.getters['PlaybackStore/isAudioPlaying'];
    },
    async playBookFragment() {
      if (this.isAudioPlaying()) {
        this.$store.dispatch('PlaybackStore/pause');
      }
      await PublicationLogicService.immediatePlayAudioFromStartRange(
        this.$store,
        this.$router
      );
    },
    toCategoryLink(category) {
      let tempCategoryName = category;
      const trimAndEqual =
        this.bookCategories.findIndex(function(item) {
          tempCategoryName = item;
          return item.trim() === category;
        }) === -1;
      return trimAndEqual
        ? { name: AppStateEnum.MANAGE_PUBLICATION }
        : this.$store.getters['LibraryStore/toCategoryLink']({
            category: tempCategoryName
          });
    }
  }
};
</script>

<style lang="less" src="./AboutViewOcean.less"></style>
