<template>
  <BaseButton
    nuxt
    :to="religionLink"
    elevation="0"
    class="religion-item"
    :class="{ '-wide': isWide }"
  >
    <div>
      <BaseIcon :stripe-url="spriteReligions" :icon-name="religionIco" />
    </div>
    <div>{{ religionName }}</div>
  </BaseButton>
</template>
<script>
import BaseIcon from '@/components/base/BaseIcon/BaseIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';

import spriteReligions from '@/assets/images/svg-images/icons/religions/sprite-religions.svg';

export default {
  name: 'ReligionItem',
  components: { BaseIcon, BaseButton },
  props: {
    religionLink: {
      type: Object,
      default: () => ({})
    },
    isWide: {
      type: Boolean,
      default: false
    },
    religionIco: {
      type: String,
      default: ''
    },
    religionName: {
      type: String,
      default: 'Religion'
    }
  },
  data() {
    return {
      spriteReligions
    };
  }
};
</script>
